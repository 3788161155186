const isEmpty = (obj: Record<any, any>) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) return false;
  }

  return true;
};

export const isEmptyObject = (value?: any) => {
  if (value === null) return false;

  if (typeof value !== 'object') return false;

  const proto = Object.getPrototypeOf(value);
  if (proto !== null && proto !== Object.prototype) return false;

  return isEmpty(value);
};

export const removeEmptyObjectOperators = (obj: Record<any, any> = {}) => {
  const newObj: Record<any, any> = {};

  if (isEmptyObject(obj)) return newObj;

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '' && obj[key] !== null && !isEmptyObject(obj[key]))
      if (typeof obj[key] !== 'object') {
        newObj[key] = obj[key];
      } else {
        newObj[key] = removeEmptyObjectOperators(obj[key]);
      }
  });

  return newObj;
};
