// react
import { useEffect } from 'react';

// next
import { useRouter } from 'next/router';

// hooks
import { useAuth } from 'src/hooks/useAuth';

// components
import Spinner from 'src/@core/components/spinner';

// utils
import { decodeJWTPayload } from 'src/@core/utils/auth';
import { isWhitelistedDomain } from 'src/@core/utils/queryParams';

const Home = () => {
  // hooks
  const auth = useAuth();
  const { isReady, replace } = useRouter();

  useEffect(() => {
    // router is not yet ready or is in the process of redirecting
    if (
      auth.loading ||
      !isReady ||
      sessionStorage.getItem('hasRedirected') ||
      !auth.user ||
      decodeJWTPayload(localStorage.getItem('accessToken'))?.is_password_otp
    )
      return;

    const returnUrl = sessionStorage.getItem('returnUrl');

    if (!returnUrl) {
      replace('/dashboard/');

      return;
    }

    // if the URL is absolute (not relative to the project) and not in the domain whitelist,
    // then notify the user that they are being redirected away from this website
    if (returnUrl.charAt(0) !== '/' && !isWhitelistedDomain(returnUrl)) {
      replace('/redirect-notification/');

      return;
    }

    sessionStorage.removeItem('returnUrl');

    replace(returnUrl);
  }, [auth.loading, auth.user, isReady, replace]);

  return <Spinner />;
};

export default Home;
