// next
import type { NextRouter } from 'next/router';

// utils
import { removeEmptyObjectOperators } from '../object';
import { splitOnLastOccurrenceOfSubstring } from '../string';

const domainWhitelist = [
  'protecht.com',
  'requestmyrefund.com',
  'refund.support',
];

export const isWhitelistedDomain = (redirectUrl?: string | string[] | null) => {
  if (!redirectUrl || (Array.isArray(redirectUrl) && !redirectUrl?.length))
    return false;

  let currentRedirectUrl = redirectUrl as string;

  // if the input is an array of strings, set it to the first index
  if (Array.isArray(redirectUrl) && redirectUrl.length)
    currentRedirectUrl = redirectUrl[0];

  // if the redirectUrl is found in any of the domainWhitelist values, break out early
  return domainWhitelist.some((value) => {
    // get the domain and top-level domain add as inputs to the regex
    const [domain, tld] = splitOnLastOccurrenceOfSubstring(value, '.');

    const regex = new RegExp(
      `^([a-z|A-Z])+?://([^/]+[.])?(${domain}[.]${tld}|${domain.toUpperCase()}[.]${tld.toUpperCase()})?(/.*)?$`
    );

    return regex.test(currentRedirectUrl);
  });
};

export const removeRouterQueryParams = (router: NextRouter) =>
  router.asPath.split('?')[0];

export const updateRouterQueryParams = (
  router: NextRouter,
  pathname: string,
  param: object
) => {
  router.replace({
    ...router,
    pathname,
    query: removeEmptyObjectOperators(param),
  });
};
