// packages
import dompurify from 'dompurify';
import { titleCase } from 'title-case';

// regex for any combination of spaces and punctuation - http://stackoverflow.com/a/25575009
const wordSeparators =
  /[\s\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]+/;
const capitals = /[A-Z\u00C0-\u00D6\u00D9-\u00DD]/g;

export const casingComparator = (a?: string, b?: string) => {
  if (!a && !b) return 0;

  if (!a) return 1;

  if (!b) return -1;

  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export const obfuscateEmail = (email: string) => {
  if (!email.length) return '';

  const [name, domain] = email.split('@');

  return `${name[0]}${new Array(name.length - 1).join('*')}${
    name[name.length - 1]
  }@${domain}`;
};

export const sanitize = (value: string | undefined) => {
  try {
    if (!value || typeof value !== 'string') return '';

    const sanitized = dompurify.sanitize(value, {
      // allow only certain HTML elements
      ALLOWED_TAGS: ['a', 'b', 'br', 'i', 'span', 'strong', 'u'],

      // strip all attributes except "href"
      ALLOWED_ATTR: ['href'],
    });

    return sanitized;
  } catch (error) {
    return '';
  }
};

export const snakeCase = (string: string) =>
  string
    .replace(capitals, (match) => ` ${match.toLowerCase() || match}`)
    .trim()
    .split(wordSeparators)
    .join('_');

export const sentenceCase = (string: string) => {
  if (string === void 0) string = '';

  if (!string) return '';

  const textcase = String(string)
    .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
    .replace(/([a-z])([A-Z])/g, (_, a, b) => a + '_' + b.toLowerCase())
    .replace(/[^A-Za-z0-9]+|_+/g, ' ')
    .toLowerCase();

  return textcase.charAt(0).toUpperCase() + textcase.slice(1);
};

export const splitOnLastOccurrenceOfSubstring = (
  string?: string | null,
  substring?: string | null
) => {
  if (!string || !substring) return '';

  const lastIndex = string.lastIndexOf(substring);

  if (lastIndex === -1) return string;

  const before = string.slice(0, lastIndex);

  const after = string.slice(lastIndex + 1);

  return [before, after];
};

export const titleCaseCustomCase = (string?: string | boolean | null) => {
  if (!string || typeof string === 'boolean') return '';

  return titleCase(
    string
      .replace(/^[-_]*(.)/, (_, c) => c)
      .replace(/[-_]+(.)/g, (_, c) => ' ' + c)
  );
};
